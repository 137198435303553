import React from 'react';

import Layout from '../components/Layout';
import ContentWrapper from '../components/ContentWrapper';
import SEO from '../components/seo';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <ContentWrapper>
      <h1>Privacy Policy</h1>
      <p>This privacy policy applies to our website (https://OutsideSimulator.com) and browser extension (Outside Simulator).

      </p><p>The information we gather or process is used solely for core functionality of Outside Simulator and to improve the quality and security of our service.
      Your information isn’t and has never been sold to third parties.

      </p><p>What information is being stored, or accessed?
      No information is being stored or accessed besides website usage (time on site, links clicked, etc...)

      </p><p>Usage data
      To improve the overall experience of our website and extension, we gather and log data on how our users access Outside Simulator.
      For example, we may log the locations you have selected to view.

      </p><p>Usage data is sent to Google Analytics.
      We do not send any identifying information.

      </p><p>Third-party vendors
      We use several vendors/sub-processors to conduct various aspects of our business.

      </p><p>Google Analytics	Google Analytics is an industry standard third-party analytics service that we use to better understand the usage and performance of our service(s). The information is anonymized so that it is not tied to your IP address.

      </p><p>If you have any questions about Outside Simulator’s Privacy policy, please contact us.

      </p><p>Last updated July 2nd, 2020.</p>
    </ContentWrapper>
  </Layout>
);

export default AboutPage;
